import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { GlobalVariables } from '../global/global-variables';
import { GlobalVariables } from '../../../global/global-variables';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'filters',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.css'],
})
export class FilterDialog implements OnInit {
  public ref;
  // public type;
  public filter;
  // public capacity = this.filter.capacity;
  // public distance;
  // public max_price;
  // public min_price;
  // public amenities;
  public isMobile;
  public is_city;
  public resource_types = GlobalVariables.resource_types;
  public min_prices_list = GlobalVariables.min_price_list;
  public max_prices_list = GlobalVariables.max_price_list;
  public facilities = GlobalVariables.facilities;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public snackBar: MatSnackBar
  ) // private _appGlobals: AppGlobals,
  // private loaderService: LoaderService

  {}

  ngOnInit() {}

  formatLabel(value: number) {
    return value;
  }

  openSnackBar(message: string, action: string) {
    // this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  public closeDialog(options) {
    this.ref.close(options);
  }

  setAmenity(id, event) {
    // const index = this.amenities.indexOf(id);
    // if (index > -1) {
    //     this.amenities.splice(index, 1);
    // } else {
    //     this.amenities.push(id)
    // }
    const index = this.filter.amenities.indexOf(id);
    if (event.checked) {
      this.filter.amenities.push(id);
    }
    if (index > -1) {
      this.filter.amenities.splice(index, 1);
    }
  }

  setType(id) {
    if (this.filter.type == id) {
      this.filter.type = null;
    } else {
      this.filter.type = id;
    }
  }

  filters() {
    this.closeDialog({ success: true, filter: this.filter });
  }
}
