import {
  Component,
  OnInit,
  PLATFORM_ID,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  Inject,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { GlobalVariables } from '../global/global-variables';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { environment } from '../../environments/environment';
import { SpaceService } from '../services/space.service';

declare var geolocation: any;
declare var google: any;
declare let localStorage: any;

export interface Space {
  id: number;
  name: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  loadAPI: Promise<any>;
  public workspace_type;
  public is_daypass_selected;
  public isMobile = false;
  // public geocoder = new google.maps.Geocoder();

  public s3_base_url =
    'https://' +
    environment.s3_bucket_path +
    '.s3.ap-south-1.amazonaws.com/banner_images/';
  public area = '';
  private getCityAndLocationDetails = GlobalVariables.getCityAndLocationDetails;
  @ViewChild('autocomplete', { static: false }) autocompleteElement: ElementRef;
  public autocomplete: google.maps.places.Autocomplete;
  // public geocoder = new google.maps.Geocoder();
  public geolocation: { lat: any; lng: any };
  public testimonials = GlobalVariables.testimonials;
  andheriEast = 'assets/images/category/Andheri-East.jpg';
  lowerParel = 'assets/images/category/LowerParel.jpg';
  powai = 'assets/images/category/Powai.jpg';
  thane = 'assets/images/category/Thane.jpg';
  bkc = 'assets/images/category/BKC.jpg';

  @ViewChild('slickReviewsModal', { static: false })
  slickReviewsModal: SlickCarouselComponent;
  public reviewsConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    variableHeight: false,
    autoplay: true,
    autoplaySpeed: 1500,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1167,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  @ViewChild('slickTestimonialModal', { static: false })
  slickTestimonialModal: SlickCarouselComponent;
  public testimonialConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    variableHeight: false,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1167,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  user_lat: number;
  user_long: number;

  next() {
    this.slickReviewsModal.slickNext();
  }

  prev() {
    this.slickReviewsModal.slickPrev();
  }

  nextTestimonial() {
    this.slickTestimonialModal.slickNext();
  }

  prevTestimonial() {
    this.slickTestimonialModal.slickPrev();
  }

  constructor(
    private router: Router,
    public viewContainerRef: ViewContainerRef,
    private titleService: Title,
    private elementRef: ElementRef,
    private metaService: Meta,
    private ngZone: NgZone,
    private spaceService: SpaceService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.titleService.setTitle(
      `One Stop Shop For Coworking Spaces | Flexible and Shared Offices`
    );
    this.metaService.updateTag({
      name: 'description',
      content: `Flexo™ is your one stop shop for coworking spaces and shared offices. Use our free service to find your perfect office now. We are flexible office space experts. `,
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: ``,
    });
  }

  ngOnInit() {
    this.getCoords();
    if (window.innerWidth < 500) {
      this.isMobile = true;
    }
    this.workspace_type = 0;
    this.geolocate();
  }

  getCoords() {
    if (isPlatformBrowser(this.platformId)) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.user_lat = position.coords.latitude;
        this.user_long = position.coords.longitude;
      });
    }
  }
  ngAfterViewInit() {
    this.initAutocomplete();
    // this.getAllTrending();
  }

  public initAutocomplete() {
    // geographical location types.
    if (isPlatformBrowser(this.platformId)) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.autocompleteElement.nativeElement,
        { types: ['geocode'] }
      );

      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.onSearchSubmit(null);
        });
      });
      this.autocomplete.setFields(['address_component', 'geometry']);
    }
  }

  public geolocate() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          let circle = new google.maps.Circle({
            center: this.geolocation,
            radius: position.coords.accuracy,
          });
          this.autocomplete.setBounds(circle.getBounds());
          //this.autocomplete_1.setBounds(circle.getBounds());
        });
      }
    }
  }

  onSearchSubmit(is_near_me_selected) {
    let query_params = {};
    let location_name = '';
    let city_name = '';
    let is_city = false;
    let address_components =
      (this.autocomplete &&
        this.autocomplete.getPlace() &&
        this.autocomplete.getPlace().address_components) ||
      [];
    if (!is_near_me_selected && address_components.length) {
      // route_params += place_data.address_components[0].long_name + '-';
      let address_details = this.getCityAndLocationDetails(address_components);
      is_city = address_details.is_city;
      location_name = address_details.location_name.split(' ').join('-');
      location_name = location_name.toLowerCase();
      city_name = address_details.city_name.split(' ').join('-');
      city_name = city_name.toLowerCase();
      // let area_lat = place_data.geometry.location.lat();
      // let area_long = place_data.geometry.location.lng();
      // query_params = {};
      // let area_name = place_data.address_components[0].long_name;
    }

    if (this.geolocation) {
      let user_lat = this.geolocation.lat;
      let user_long = this.geolocation.lng;
    }
    if (is_near_me_selected) {
      query_params = { ...query_params, find_near_me: true };
    }

    let url = '';
    if (is_city) {
      url = 'in/coworking/' + city_name;
    }else {
      url = 'in/coworking-space' + '/' + city_name + '/' + location_name;
    }

    // //url change
    // if (city_name ==  'konkan-division' ) {
    //   url ='/in/coworking-space/' + city_Change + '/'+ location_name;
    // }

    // let area_param = location_name.toLowerCase();
    // let city_param = city_name.toLowerCase();
    // if (area_param && city_param) {
    //   area_param = area_param.replace(' ', '-');
    //   city_param = city_param.replace(' ', '-');
    // }
    // let area = area_param + ',' + city_param;
    // this.geocoder.geocode({ 'address': `${area}`, }, (results, status) => {
    //   if (status == google.maps.GeocoderStatus.OK) {
    //     let location = results[0].geometry.location;
    //     // this.area_lat = location.lat();
    //     // this.area_long = location.lng();
    //     localStorage.setItem('area_lat', `${location.lat()}`);
    //     localStorage.setItem('area_long', `${location.lng()}`);

    //   }
    // })
    // setTimeout(() => {
    console.log({ url, query_params });
    this.router.navigate([url], { queryParams: query_params });
    // }, 500);
  }

  onNearmeClicked() {
    this.spaceService.getCityInfo(this.user_lat, this.user_long).subscribe(
      (response) => {
        const { cityName, areaName } = response;

        this.router.navigate([`/in/coworking-space/`, cityName, areaName]);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getCityAndLocationDetails2(address_components) {
    let address_length = address_components.length;
    let main_address_component = address_components[0];
    let is_city = false;
    let city_name = '';
    let location_name = '';

    switch (address_length) {
      case 3:
        city_name = main_address_component.long_name;
        is_city = true;
        break;
      case 4:
        if (
          main_address_component.types[0] == 'locality' &&
          address_components[1].types[0] == 'administrative_area_level_2'
        ) {
          if (
            main_address_component.long_name == address_components[1].long_name
          ) {
            is_city = true;
            city_name = main_address_component.long_name;
          } else {
            location_name = main_address_component.long_name;
            city_name = address_components[1].long_name;
          }
        }
        break;
      default:
        location_name = main_address_component.long_name;
        address_components.forEach((address) => {
          if (address.types[0] == 'locality') {
            city_name = address.long_name;
          }
          if (address.types[0] == 'administrative_area_level_2') {
            city_name = address.long_name;
          }
        });
        break;
    }

    return {
      is_city,
      city_name,
      location_name,
    };
  }
}
