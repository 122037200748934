import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { GlobalVariables } from '../global/global-variables';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
    selector: 'filters',
    templateUrl: './view-more.component.html',
    styleUrls: ['./view-more.component.css']
})

export class ViewMoreDialog implements OnInit {
    public ref;
    public images;
    public id;
    public aws_base_url = "https://s3.ap-south-1.amazonaws.com/" + environment.s3_bucket_path + '/details_images/';

    constructor(
        public dialogRef: MatDialogRef<any>,
        public snackBar: MatSnackBar,

        // private _appGlobals: AppGlobals,
        // private loaderService: LoaderService

    ) { }
    @ViewChild('slickMainCarousel', { static: false }) slickMainCarousel: SlickCarouselComponent;
    public mainSliderConfig = {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'arrows': true,
        'centerMode': true,
        "dots": true,
        'centerPadding': "0",
        'swipeToSlide': true,
        'infinite': true
    };

    next() {
        this.slickMainCarousel.slickNext();
    }

    prev() {
        this.slickMainCarousel.slickPrev();
    }
    ngOnInit() {

    }
}