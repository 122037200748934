import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { GlobalVariables } from '../global/global-variables';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { AppGlobals } from '../services/app-globals';
import { SpaceService } from '../services/space.service';
import { MemberService } from '../services/member.service';
// import { LoaderService } from '../services/loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
// import {Observable} from 'rxjs';
// import {map, startWith} from 'rxjs/operators';
import { ConfirmedValidator } from '../confirmed.validator';
// import { element } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'login',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css'],
})
export class LoginDialog implements OnInit {
  public ref;
  public show_forgot_password = false;
  public form: FormGroup = new FormGroup({});
  public forgot_form: FormGroup = new FormGroup({});
  public loginForm: FormGroup = new FormGroup({});
  public otpForm: FormGroup = new FormGroup({});
  public otpFormForgot: FormGroup = new FormGroup({});
  public mobileFormForgot: FormGroup = new FormGroup({});
  public passwordFormForgot: FormGroup = new FormGroup({});
  constructor(
    public dialogRef: MatDialogRef<any>,
    private spaceService: SpaceService,
    public fb: FormBuilder,
    private _memberService: MemberService,
    public snackBar: MatSnackBar
  ) // private _appGlobals: AppGlobals,
  // private loaderService: LoaderService

  {
    this.form = fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
    });
    this.loginForm = fb.group({
      mobile: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
    });
    this.otpForm = fb.group({
      otp: ['', [Validators.required]],
    });
  }
  get f() {
    return this.form.controls;
  }
  get lf() {
    return this.loginForm.controls;
  }
  get of() {
    return this.otpForm.controls;
  }
  public show_otp = false;
  public mobile;
  public email;
  public name;
  public otp;
  public otp_sent: boolean = false;
  public login: boolean = true;
  public is_user_exist: boolean = true;
  ngOnInit() {}
  //  public signUp() {

  //  }

  public Login() {
    this._memberService
      .login({ mobile: this.mobile })
      .then((res) => {
        this.login = false;
        if (res && res.success) {
          this.is_user_exist = true;
          this.sendOtp();
        } else {
          this.openSnackBar(res.message, 'Error');
          this.is_user_exist = false;
        }
      })
      .catch((error) => {
        this.openSnackBar('Some error occured', 'Error');
      });
  }

  public addWorker() {
    this._memberService
      .addWorker({ mobile: this.mobile, email: this.email, name: this.name })
      .then((data) => {
        if (data && data.success) {
          this.openSnackBar(data.message, 'Error');
          this.is_user_exist = true;
          this.sendOtp();
        } else {
          this.openSnackBar(data.message, 'Error');
        }
      })
      .catch((error) => {
        this.openSnackBar('Some error occured', 'Error');
      });
  }

  public sendOtp() {
    this._memberService
      .sendOTP({ mobile: this.mobile })
      .then((data) => {
        if (data && data.success) {
          this.otp_sent = true;
          this.openSnackBar(data.message, 'Dismiss');
        } else {
          this.openSnackBar(data.message, 'Dismiss');
        }
      })
      .catch((error) => {
        this.openSnackBar('Some error occured', 'Error');
      });
  }

  public verifyOtp() {
    this._memberService
      .verifyOTP({ mobile: this.mobile, otp: this.otp })
      .then((data) => {
        if (data && data.success) {
          this.openSnackBar(data.message, 'Dismiss');
          this.closeDialog(data);
        } else {
          this.openSnackBar(data.message, 'Dismiss');
        }
      })
      .catch((error) => {
        this.openSnackBar('Some error occured', 'Error');
      });
  }
  // public forgotPassword() {
  //   this._memberService.forgotPassword(this.mobile)
  //     .then(res => {
  //       if (res.success) {
  //         this.otp_sent_forgot = true;
  //         this.show_mobile = false;
  //         this.show_otp = true;
  //         // this.alert_success = 'success';
  //         this.openSnackBar(res.message, "Dismiss");
  //         // this.otp_sent = true;
  //       } else {
  //         // this.alert_success = 'warn';
  //         this.openSnackBar(res.message, "Dismiss");
  //       }
  //     })
  // }

  openSnackBar(message: string, action: string) {
    // this.loaderService.displayLoader(false);
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  public closeDialog(options) {
    this.ref.close(options);
  }
}
