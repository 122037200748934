<div class="detail-container">
    <p style="font-size: 30px; text-align: center">Filters</p>
    <i class=" cross material-icons" style="cursor: pointer;" (click)="closeDialog(null)">
        close
    </i>
</div>
<hr>

<div class="form-container">
    <div>
        <p class="text-head">Type</p>
        <br>
        <ul>
            <li>
                <mat-checkbox (change)="setType(null)" [checked]="filter.type == null" color="accent">All</mat-checkbox>
            </li>
            <li *ngFor="let resource_type of resource_types">
                <mat-checkbox (change)="setType(resource_type.id)" color="accent"
                    [checked]="filter.type == resource_type.id">
                    {{resource_type.name}}
                </mat-checkbox>
            </li>
        </ul>
    </div>
    <hr>
    <!-- <div>
        <p class="text-head">Capacity</p>
        <mat-slider style="width: 100%" class="min" [displayWith]="formatLabel" thumbLabel min="1" max="200"
            [(ngModel)]="filter.capacity" name="capacity"></mat-slider>
        <hr>

    </div> -->
    <div *ngIf="filter.type != null">
        <hr>
        <p class="text-head">Price Range</p>
        <div class="price-range">
            <mat-form-field appearance="outline">
                <mat-label>From</mat-label>
                <mat-select [(ngModel)]="filter.min_price">
                    <mat-option *ngFor="let price of min_prices_list" [value]="price">Rs. {{price}}/-</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>To</mat-label>
                <mat-select [(ngModel)]="filter.max_price">
                    <mat-option *ngFor="let price of max_prices_list" [value]="price">Rs. {{price}}/-</mat-option>
                    <mat-option value=null>Above</mat-option>
                </mat-select>
            </mat-form-field>
            <!--
                <mat-slider style="width: 50%;" class="min" [displayWith]="formatLabel" thumbLabel min="1"
                  max="100000" [(ngModel)]="min_price" name="price"></mat-slider>
                &nbsp;&nbsp; <div>To</div>&nbsp;&nbsp;
                <mat-slider style="width: 50%;" class="min" [displayWith]="formatLabel" thumbLabel min="1"
                  max="100000" [(ngModel)]="max_price" name="price"></mat-slider> -->
        </div>
    </div>
    <div *ngIf="!is_city">
        <p class="text-head">Distance</p>
        <mat-slider style="width: 100%" class="min" [displayWith]="formatLabel" thumbLabel min="1" max="200"
            [(ngModel)]="filter.radius" name="distance"></mat-slider>
        <hr>

    </div>

    <div>
        <p class="text-head">Amenities</p>
        <br>
        <ul>
            <li *ngFor="let facility of facilities">
                <mat-checkbox (change)="setAmenity(facility.id, $event)"
                    [checked]="filter.amenities.includes(facility.id)" color="accent">
                    {{facility.name}}
                </mat-checkbox>
            </li>
        </ul>
    </div>

</div>
<hr>
<div>
    <div class="book-btn text-center bottom-fixed-button">
        <a (click)="filters()">Apply</a>
    </div>
</div>

<!-- <button type="submit" class="btn v1 mar-top-10 self-center" (click)="filters()">Apply</button> -->
<!-- <div *ngIf="isMobile" class="bottom-fixed-container">
        <div class="book-btn text-center bottom-fixed-button1">
            <a (click)="filters()">Apply</a>
        </div>
    </div> -->

<!-- <div class="book-btn text-center">
        <button (click)="filters()">Apply</button>
    </div> -->