<div class="privacy-content">
  <!--section -->
  <section class="parallax-section" data-scrollax-parent="true">
    <div class="bg1 par-elem " data-scrollax="properties: { translateY: '30%' }"
      style="background-color: gray; transform: translateZ(0px) translateY(-4.22535%);">
    </div>
    <!-- <img class="bg1 par-elem " src="assets/images/Contact.jpg" alt=""
      style="transform: translateZ(0px) translateY(-4.22535%);"> -->
    <div class="privacy-container">
      <div class="col-md-8 offset-md-2 text-center">
        <h2 class="section-title v1" style="color: white;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 0px;
        padding-bottom: 0px;">TERMS OF USE</h2>
      </div>
    </div>
  </section>
  <!-- section end -->
  <!--section -->
  <section class="gray-section" id="sec1">
    <div class="privacy-container">
      <div class="row">
        <div class="col-md-12">
          <div class="list-single-main-wrapper fl-wrap" id="sec2">
            <!-- article> -->
            <article>
              <div style="text-align: left;">
                <p>
                  Welcome to <a href="flexospaces.com">www.flexospaces.com</a>. This website and mobile applications are
                  owned and operated by Flexo Spaces. By visiting our website and mobile applications and accessing the
                  information, resources, services, products, and tools we provide, you understand and agree to accept
                  and adhere to the following terms and conditions as stated in this policy (hereinafter referred to as
                  the ‘Agreement’), along with the terms and conditions as stated in our Privacy Policy (please refer to
                  the Privacy Policy section below for more information).
                </p>
                <p>
                  We reserve the right to change this Agreement from time to time with/without notice. You acknowledge
                  and agree that it is your responsibility to review this Agreement periodically to familiarize yourself
                  with any modifications. Your continued use of this site after such modifications will constitute
                  acknowledgment and agreement of the modified terms and conditions.
                </p>
                <p>
                  <b>PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS
                    FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                  </b></p>
                <p>
                  This Agreement governs your use of this website <a href="flexospaces.com">www.flexospaces.com</a> and
                  mobile applications (hereinafter collectively referred to as the ‘Website’), This Agreement includes
                  and incorporates by this reference, the policies and guidelines referred below. Flexo Spaces reserves
                  the right to change or revise the terms and conditions of this Agreement at any time by posting any
                  changes or a revised Agreement on this Website. Flexo Spaces will/will not alert you that changes or
                  revisions have been made by indicating on the top of this Agreement the date it was last revised. The
                  changed or revised Agreement will be effective immediately after it is posted on this Website. Your
                  use of the Website following the posting of any such changes or of a revised Agreement will constitute
                  your acceptance of any such changes or revisions. Flexo Spaces encourages you to review this Agreement
                  whenever you visit the Website to make sure that you understand the terms and conditions governing use
                  of the Website. This Agreement does not alter in any way the terms or conditions of any other written
                  agreement you may have with Flexo Spaces for other products or services. If you do not agree to this
                  Agreement (including any referenced policies or guidelines), please immediately terminate your use of
                  the Website.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    RESPONSIBLE USE AND CONDUCT:
                  </h3>
                </div>
                <ul>
                  <li>
                    <p>
                      In order to access our Resources, you may be required to provide certain information about
                      yourself (such as identification, email, phone number, contact details, etc.) as part of the
                      registration process, or as part of your ability to use the Resources. You agree that any
                      information you provide will always be accurate, correct, and up to date.
                    </p>
                  </li>
                  <li>
                    <p>
                      You are responsible for maintaining the confidentiality of any login information associated with
                      any account you use to access our Resources/ . Accordingly, you are responsible for all activities
                      that occur under your accounts.
                    </p>
                  </li>
                  <li>
                    <p>
                      Accessing (or attempting to access) any of our Resources/ by any means other than through the
                      means we provide, is strictly prohibited. You specifically agree not to access (or attempt to
                      access) any of our Resources/ through any automated, unethical or unconventional means.
                    </p>
                  </li>
                  <li>
                    <p>
                      Engaging in any activity that disrupts or interferes with our Resources/ , including the servers
                      and/or networks to which our Resources / are located or connected, is strictly prohibited.
                    </p>
                  </li>
                  <li>
                    <p>
                      Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources / is strictly
                      prohibited.
                    </p>
                  </li>
                  <li>
                    <p>
                      You are solely responsible for any consequences, losses, or damages that we may directly or
                      indirectly incur or suffer due to any unauthorized activities conducted by you, as explained
                      above, and may incur criminal or civil liability.
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    PRIVACY:
                  </h3>
                </div>
                <p>
                  Flexo Spaces believes strongly in protecting user privacy, which is why a separate Privacy Policy have
                  been created in order to explain in detail how we collect, manage, process, secure, and store your
                  private information. Please refer to Flexo Spaces privacy policy, incorporated by reference herein,
                  that is posted on the Website.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    CUSTOMER SOLICITATION:
                  </h3>
                </div>
                <p>
                  Unless you notify our third party call center representatives or direct Flexo Spaces sales
                  representatives, while they are calling you, of your desire to opt out from further direct company
                  communications and solicitations, you are agreeing to continue to receive further emails and call
                  solicitations from Flexo Spaces and its designated in house or third party call team(s).
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    OPT OUT PROCEDURE:
                  </h3>
                </div>
                <p>
                  We provide 3 easy ways to opt out of from future solicitations -
                </p>
                <ul>
                  <li>
                    <p>
                      1. You may use the opt out link found in any email solicitation that you may receive.
                    </p>
                  </li>
                  <li>
                    <p>
                      2. You may also choose to opt out, via sending your email address to: hello@flexospaces.com
                    </p>
                  </li>
                  <li>
                    <p>
                      3. You may send a written remove request to Flexo Spaces sales representatives, while they are
                      calling you, of your desire to opt out from further direct company communications and
                      solicitations,
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    PROPRIETARY RIGHTS:
                  </h3>
                </div>
                <p>
                  Flexo Spaces has proprietary rights . Flexo Spaces also has rights to all trademarks and trade dress
                  and specific layouts of this webpage, including calls to action, text placement, images and other
                  information.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    CONTENT, INTELLECTUAL PROPERTY, THIRD PARTY LINKS:
                  </h3>
                </div>
                <p>
                  This Website also offers information, both directly and through indirect links to third-party
                  websites, about (kind of information). Flexo Spaces does not always create the information offered on
                  this Website; instead the information is often gathered from other sources. To the extent that Flexo
                  Spaces does create the content on this Website, such content is protected by intellectual property
                  laws of the India, foreign nations, and international bodies. Unauthorized use of the material may
                  violate copyright, trademark, and/or other laws. You acknowledge that your use of the content on this
                  Website is for personal, non-commercial use. Any links to third-party websites are provided solely as
                  a convenience to you. Flexo Spaces does not endorse the contents on any such third-party
                  websites. Flexo Spaces is not responsible for the content of or any damage that may result from your
                  access to or reliance on these third-party websites. If you link to third-party websites, you do so at
                  your own risk.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    USE OF WEBSITE:
                  </h3>
                </div>
                <p>
                  Flexo Spaces is not responsible for any damages resulting from use of this website by anyone. You will
                  not use the Website for illegal purposes. You will -
                </p>
                <ul>
                  <li>
                    <p>
                      1. Abide by all applicable local, state, national, and international laws and regulations in your
                      use of the Website (including laws regarding intellectual property).
                    </p>
                  </li>
                  <li>
                    <p>
                      2. Not interfere with or disrupt the use and enjoyment of the Website by other users.
                    </p>
                  </li>
                  <li>
                    <p>
                      3. Not resell material on the Website.
                    </p>
                  </li>
                  <li>
                    <p>
                      4. Not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any
                      other type of unsolicited communication.
                    </p>
                  </li>
                  <li>
                    <p>
                      5. Not defame, harass, abuse, or disrupt other users of the Website.
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    LICENSE:
                  </h3>
                </div>
                <p>
                  By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the
                  content and materials on the Website in connection with your normal, non-commercial use of the
                  Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such content
                  or information without express written authorization from Flexo Spaces or the applicable third party
                  (if third party content is at issue).
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    BLOGS:
                  </h3>
                </div>
                <ul>
                  <li>
                    <p>
                      We may provide various open communication tools on our website, such as blog comments, blog posts,
                      public chat, forums, message boards, newsgroups, product ratings and reviews, various social media
                      services, etc. You understand that generally we do not pre-screen or monitor the content posted by
                      users of these various communication tools, which means that if you choose to use these tools to
                      submit any type of content to our website, then it is your personal responsibility to use these
                      tools in a responsible and ethical manner. By posting information or otherwise using any open
                      communication tools as mentioned, you agree that you will not upload, post, share, or otherwise
                      distribute any content that:
                    </p>
                  </li>
                  <li>
                    <p>
                      Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent,
                      deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit
                      language;
                    </p>
                  </li>
                  <li>
                    <p>
                      Infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any
                      party;
                    </p>
                  </li>
                  <li>
                    <p>
                      Contains any type of unauthorized or unsolicited advertising;
                    </p>
                  </li>
                  <li>
                    <p>
                      Impersonates any person or entity, including any www.flexospaces.com/Flexo Spaces employees or
                      representatives.
                    </p>
                  </li>
                  <li>
                    <p>
                      We have the right at our sole discretion to remove any content that, we feel in our judgment does
                      not comply with this User Agreement, along with any content that we feel is otherwise offensive,
                      harmful, objectionable, inaccurate, or violates any 3rd party copyrights or trademarks. We are not
                      responsible for any delay or failure in removing such content. If you post content that we choose
                      to remove, you hereby consent to such removal, and consent to waive any claim against us.
                    </p>
                  </li>
                  <li>
                    <p>
                      We do not assume any liability for any content posted by you or any other 3rd party users of our
                      website. However, any content posted by you using any open communication tools on our website,
                      provided that it doesn't violate or infringe on any 3rd party copyrights or trademarks, becomes
                      the property of Flexo Spaces, and as such, gives us a perpetual, irrevocable, worldwide,
                      royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display
                      and/or distribute as we see fit. This only refers and applies to content posted via open
                      communication tools as described, and does not refer to information that is provided as part of
                      the registration process, necessary in order to use our Resources. All information provided as
                      part of our registration process is covered by our Privacy Policy.
                    </p>
                  </li>
                  <li>
                    <p>
                      You agree to indemnify and hold harmless www.flexospaces.com a digital property of Flexo
                      Spaces and its parent company and affiliates, and their directors, officers, managers, employees,
                      donors, agents, and licensors, from and against all losses, expenses, damages and costs, including
                      reasonable attorneys' fees, resulting from any violation of this User Agreement or the failure to
                      fulfill any obligations relating to your account incurred by you or any other person using your
                      account. We reserve the right to take over the exclusive defense of any claim for which we are
                      entitled to indemnification under this User Agreement. In such event, you shall provide us with
                      such cooperation as is reasonably requested by us.
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    POSTING:
                  </h3>
                </div>
                <p>By posting, storing, or transmitting any content on the Website, you hereby grant Flexo Spaces a
                  perpetual, worldwide, non-exclusive, royalty-free, assignable, right and license to use, copy,
                  display, perform, create derivative works from, distribute, have distributed, transmit and assign such
                  content in any form, in all media now known or hereinafter created, anywhere in the world. Flexo
                  Spaces does not have the ability to control the nature of the user-generated content offered through
                  the Website. You are solely responsible for your interactions with other users of the Website and any
                  content you post. Flexo Spaces is not liable for any damage or harm resulting from any posts by or
                  interactions between users. Flexo Spaces reserves the right, but has no obligation, to monitor
                  interactions between and among users of the Website and to remove any content Flexo Spaces deems
                  objectionable, in Flexo Spaces's sole discretion.</p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    DISCLAIMER OF WARRANTIES:
                  </h3>
                </div>
                <p>
                  Your use of this website and/or are at your sole risk. The website and are offered on an "as is" and
                  "as available" basis. Flexo Spaces expressly disclaims all warranties of any kind, whether express or
                  implied, including, but not limited to, implied warranties of merchantability, fitness for a
                  particular purpose and non-infringement with respect to the or website content, or any reliance upon
                  or use of the website content or
                </p>
                <p>
                  Without limiting the generality of the foregoing, Flexo Spaces makes no warranty:
                </p>
                <ul>
                  <li>
                    <p>
                      That the information provided on this website is accurate, reliable, complete, or timely.
                    </p>
                  </li>
                  <li>
                    <p>
                      That the links to third-party websites are to information that is accurate, reliable, complete, or
                      timely.
                    </p>
                  </li>
                  <li>
                    <p>
                      No advice or information, whether oral or written, obtained by you from this website will create
                      any warranty not expressly stated herein
                    </p>
                  </li>
                  <p>
                    Some jurisdictions do not allow the exclusion of certain warranties, so some of the above exclusions
                    may not apply to you.
                  </p>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    LIMITATION OF LIABILITY:
                  </h3>
                </div>
                <p>
                  Flexo Spaces’s entire liability, and your exclusive remedy, in law, in equity, or otherwise, with
                  respect to the website content / and/or for any breach of this agreement is solely limited to the
                  amount you paid
                </p>
                <p>
                  Flexo Spaces will not be liable for any direct, indirect, incidental, special or consequential damages
                  in connection with this agreement &/or in any manner, including liabilities resulting from:
                </p>
                <ul>
                  <li>
                    <p>
                      The use or the inability to use the website content / ;
                    </p>
                  </li>
                  <li>
                    <p>
                      The cost of procuring substitute content / ;
                    </p>
                  </li>
                  <li>
                    <p>
                      Any information obtained / purchased or transactions entered into through the website; or
                    </p>
                  </li>
                  <li>
                    <p>
                      Any lost profits you allege.
                    </p>
                  </li>
                </ul>
                <p>
                  Some jurisdictions do not allow the limitation or exclusion of liability for incidental or
                  consequential damages so some of the above limitations may not apply to you.
                </p>
                <p>
                  Conjunction with the Limitation of Liability as explained above, you expressly understand and agree
                  that any claim against us shall be limited to the amount you paid, if any, for use
                  of . www.flexospaces.com/Flexo Spaces will not be liable for any direct, indirect, incidental,
                  consequential or exemplary loss or damages which may be incurred by you as a result of using our
                  Resources / , or as a result of any changes, data loss or corruption, cancellation, loss of access, or
                  downtime to the full extent that applicable limitation of liability laws apply.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    INDEMNIFICATION:
                  </h3>
                </div>
                <p>
                  You will release, indemnify, defend and hold harmless Flexo Spaces, and any of its contractors,
                  agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities,
                  claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third
                  parties relating to or arising out of
                </p>
                <ul>
                  <li>
                    <p>
                      This Agreement or the breach of your warranties, representations and obligations under this
                      Agreement;
                    </p>
                  </li>
                  <li>
                    <p>
                      The Website content or your use of the Website content;
                    </p>
                  </li>
                  <li>
                    <p>
                      Any intellectual property or other proprietary right of any person or entity;
                    </p>
                  </li>
                  <li>
                    <p>
                      Your violation of any provision of this Agreement; or
                    </p>
                  </li>
                  <li>
                    <p>
                      Any information or data you supplied to Flexo Spaces.
                    </p>
                  </li>
                </ul>
                <p>
                  When Flexo Spaces is threatened with suit or sued by a third party, Flexo Spaces may seek written
                  assurances from you concerning your promise to indemnify Flexo Spaces; your failure to provide such
                  assurances may be considered by Flexo Spaces to be a material breach of this Agreement. Flexo
                  Spaces will have the right to participate in any defense by you of a third-party claim related to your
                  use of any of the Website content / , with the counsel of Flexo Spaces’s choice at its expense. Flexo
                  Spaces will reasonably cooperate in any defense by you of a third-party claim at your request and
                  expense. You will have sole responsibility to defend Flexo Spaces against any claim, but you must
                  receive Flexo Spaces’s prior written consent regarding any related settlement. The terms of this
                  provision will survive any termination or cancellation of this Agreement or your use of the Website.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    COPYRIGHTS / TRADEMARKS:
                  </h3>
                </div>
                <p>
                  All content and materials available on www.flexospaces.com, including but not limited to text,
                  graphics, website name, code, images and logos are the intellectual property of Flexo Spaces and are
                  protected by applicable copyright and trademark law.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    AGREEMENT TO BE BOUND:
                  </h3>
                </div>
                <p>
                  BY USING THIS WEBSITE OR ORDERING , YOU ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO BE BOUND BY THIS
                  AGREEMENT AND ALL TERMS AND CONDITIONS ON THIS WEBSITE.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    GENERAL CLAUSE:
                  </h3>
                </div>
                <ul>
                  <li>
                    <p>
                      Force Majeure: Flexo Spaces will not be deemed in default hereunder or held responsible for any
                      cessation, interruption or delay in the performance of its obligations hereunder due to
                      earthquake, flood, fire, storm, natural disaster, act of God, war, pandemic, epidemic, terrorism,
                      armed conflict, labor strike, lockout, or boycott or any other unforeseen circumstance
                    </p>
                  </li>
                  <li>
                    <p>
                      Cessation of Operation: Flexo Spaces may at any time, in its sole discretion and without advance
                      notice to you, cease operation of the Website
                    </p>
                  </li>
                  <li>
                    <p>
                      Entire Agreement: This Agreement comprises the entire agreement between you and Flexo Spaces and
                      supersedes any prior agreements pertaining to the subject matter contained herein.
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    GOVERNING LAW:
                  </h3>
                </div>
                <p>
                  This website is controlled by Flexo Spaces from our offices located in the state of Maharashtra,
                  India. It can be accessed by most countries around the world. As each country has laws that may differ
                  from those of Maharashtra, India, by accessing our website, you agree that the statutes and laws
                  of Maharashtra, India, without regard to its conflict of law principles to the contrary and the United
                  Nations Convention on the International Sales of Goods, will apply to all matters relating to the use
                  of this website.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    EFFECT OF WAIVER:
                  </h3>
                </div>
                <p>
                  The failure of Flexo Spaces to exercise or enforce any right or provision of this Agreement will not
                  constitute a waiver of such right or provision. If any provision of this Agreement is found by a court
                  of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor
                  to give effect to the parties' intentions as reflected in the provision and the other provisions of
                  this Agreement remain in full force and effect.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    GOVERNING LAW/JURISDICTION:
                  </h3>
                </div>
                <p>
                  This Website originates from the Mumbai, Maharashtra, India. This Agreement will be governed by the
                  laws of the State of Maharashtra, India. It can be accessed by most countries around the world. As
                  each country has laws that may differ from those of Maharashtra, India without regard to its conflict
                  of law principles to the contrary. Neither you nor Flexo Spaces will commenceMaharashtra), India. This
                  Agreement will be governed by the laws of the State of Maharashtra, India. It can be accessed by most
                  countries around the world. As each country has laws that may differ from those of Maharashtra, India
                  without regard to its conflict of law principles to the contrary. Neither you nor or prosecute any
                  suit, proceeding or claim to enforce the provisions of this Agreement, to recover damages for breach
                  of or default of this Agreement, or otherwise arising under or by reason of this Agreement, other than
                  in courts located in State of Maharashtra, India. It can be accessed by most countries around the
                  world. As each country has laws that may differ from those of Maharashtra, India. By using this
                  Website or ordering , you consent to the jurisdiction and venue of such courts in connection with any
                  action, suit, proceeding or claim arising under or by reason of this Agreement. You hereby waive any
                  right to trial by jury arising out of this Agreement and any related documents.
                </p>
                <p>
                  This website is controlled by Flexo Spaces from our offices located in the state of Maharashtra,
                  India. It can be accessed by most countries around the world. As each country has laws that may differ
                  from those of Maharashtra, India, by accessing our website, you agree that the statutes and laws
                  of Maharashtra, India without regard to the conflict of laws and the United Nations Convention on the
                  International Sales of Goods, will apply to all matters relating to the use of this website.
                </p>
                <p>
                  Furthermore, any action to enforce this User Agreement shall be brought in the courts having
                  jurisdiction over such issue, located in Maharashtra, India. You hereby agree to judgement passed by
                  such courts and waive any right to jurisdictional, venue, or inconvenient forum objections to such
                  courts.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    STATUTE OF LIMITATION:
                  </h3>
                </div>
                <p>
                  You agree that regardless of any statute or law to the contrary, any claim or cause of action arising
                  out of or related to use of the Website or or this Agreement must be filed within one (1) year after
                  such claim or cause of action arose else be forever barred.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    WAIVER OF CLASS ACTION RIGHTS:
                  </h3>
                </div>
                <p>
                  BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIM
                  WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING
                  OUT OF OR RELATING TO OR IN CONNECTION WITH THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    TERMINATION:
                  </h3>
                </div>
                <p>
                  Flexo Spaces reserves the right to terminate your access to the Website if it reasonably believes, in
                  its sole discretion, that you have breached any of the terms and conditions of this Agreement.
                  Following termination, you will not be permitted to use the Website If your access to the Website is
                  terminated, Flexo Spaces reserves the right to exercise whatever means it deems necessary to prevent
                  unauthorized access of the Website. This Agreement will survive indefinitely unless and until Flexo
                  Spaces chooses, in its sole discretion and without advance notice to you, to terminate it.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    DOMESTIC USE:
                  </h3>
                </div>
                <p>
                  Flexo Spaces makes no representation that the Website or are appropriate or available for use in
                  locations outside India. Users who access the Website from outside India do so at their own risk and
                  initiative and must bear all responsibility for compliance with any applicable local laws.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    GUARANTEE:
                  </h3>
                </div>
                <p>
                  Unless otherwise expressed, Flexo Spaces & www.flexospaces.com expressly disclaims all warranties and
                  conditions of any kind, whether express or implied, including, but not limited to the implied
                  warranties and conditions of merchantability, fitness of content / for a particular purpose and
                  non-infringement.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    ASSIGNMENT:
                  </h3>
                </div>
                <p>
                  You may not assign your rights and obligations under this Agreement to anyone. Flexo Spaces may assign
                  its rights and obligations under this Agreement in its sole discretion and without advance notice to
                  you.
                </p>
                <p>
                  BY USING THIS WEBSITE OR ORDERING FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND
                  CONDITIONS OF THIS AGREEMENT.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    CONTACT INFORMATION:
                  </h3>
                </div>
                <p>
                  If you have any questions or comments about these our Terms of Service as outlined above, you can
                  contact us at: hello@flexospaces.com or write to us at
                </p>
                <p>
                  Flexo Spaces</p>
                <p>
                  L3A/102 Oakland Park,Yamuna Nagar, Andheri West,Mumbai,Maharashtra,400053
                </p>
              </div>
            </article>
          </div>
        </div>
        <!--box-widget-wrap -->
      </div>
    </div>
  </section>

  <!-- section end -->
</div>