<div class="form-container">
  &nbsp;
  <mat-icon class="cross material-icons" (click)="closeDialog(null)">
    close
  </mat-icon>
  <div *ngIf="login">
    <div class="detail-container">
      <p style="flex: 1; font-size: 30px; text-align: center; margin: auto">
        Login
      </p>
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="Login()" class="space-form">
      <mat-form-field
        style="align-items: center"
        class="form-field"
        appearance="outline"
      >
        <mat-label>Mobile</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="mobile"
          formControlName="mobile"
          maxlength="10"
          minlength="10"
          name="mobile"
          placeholder="Enter mobile number"
          required
        />
        <mat-error *ngIf="lf.mobile.errors?.required"
          >Mobile is required.</mat-error
        >
      </mat-form-field>
      <div>
        <button
          style="align-self: center"
          class="btn v1 mar-top-10"
          type="submit"
          [disabled]="!loginForm.valid"
        >
          Submit
        </button>
        <!-- <button style = "align-items: center;" mat-raised-button color="accent" type="submit" [disabled]="!loginForm.form.valid">Submit</button> -->
      </div>
    </form>
  </div>
  <div *ngIf="!is_user_exist">
    <div class="detail-container">
      <p style="flex: 1; font-size: 30px; text-align: center">Fill Details</p>
    </div>
    <form (ngSubmit)="addWorker()" [formGroup]="form" class="space-form">
      <mat-form-field
        style="align-items: center"
        class="form-field"
        appearance="outline"
      >
        <mat-label>Name</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="name"
          name="name"
          formControlName="name"
          placeholder="Enter Name"
          required
        />
        <mat-error *ngIf="f.name.errors?.required">Name is required.</mat-error>
      </mat-form-field>
      <mat-form-field
        style="align-items: center"
        class="form-field"
        appearance="outline"
      >
        <mat-label>Mobile</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="mobile"
          formControlName="mobile"
          maxlength="10"
          minlength="10"
          name="mobile"
          placeholder="Enter mobile number"
          required
        />
        <!-- <mat-error *ngIf="f.mobile.errors?.pattern()">Not a valid Mobile</mat-error> -->
        <mat-error *ngIf="f.mobile.errors?.required"
          >Mobile is required.</mat-error
        >
      </mat-form-field>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Email</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="email"
          name="email"
          formControlName="email"
          placeholder="Enter Email"
          required
        />
        <mat-error *ngIf="f.email.errors?.email">Not a valid Email.</mat-error>
        <mat-error *ngIf="f.email.errors?.required"
          >Email is required.</mat-error
        >
      </mat-form-field>
      <div>
        <button
          style="align-self: center"
          class="btn v1 mar-top-10"
          type="submit"
          [disabled]="!form.valid"
        >
          Submit
        </button>
        <!-- <button style = "align-items: center;" mat-raised-button color="accent" type="submit" [disabled]="!signupForm.form.valid">Submit</button> -->
      </div>
    </form>
  </div>
  <div *ngIf="otp_sent && is_user_exist">
    <div class="detail-container">
      <p style="flex: 1; font-size: 30px; text-align: center">Enter OTP</p>
    </div>
    <form [formGroup]="otpForm" (ngSubmit)="verifyOtp()" class="space-form">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>OTP</mat-label>
        <input
          type="password"
          matInput
          [(ngModel)]="otp"
          formControlName="otp"
          name="otp"
          placeholder="Enter OTP"
          required
        />
        <mat-error *ngIf="of.otp.errors?.required">OTP is required.</mat-error>
      </mat-form-field>
      <div>
        <button
          style="align-self: center"
          class="btn v1 mar-top-10"
          type="submit"
          [disabled]="!otpForm.valid"
        >
          Submit
        </button>
        <!-- <button style = "align-items: center;" mat-raised-button color="accent" type="submit" [disabled]="!otpForm.form.valid">Submit</button> -->
      </div>
    </form>
  </div>
</div>
