// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://apis.flexospaces.com/',
  //apiUrl: 'http://localhost:3302/',
  angularUrl: `http://localhost:44300/`,
  s3_bucket_path: 'worker-app',
  webDomain: '', //flexospaces.com/
  // mapKey: 'AIzaSyAxSNC4EcovTjboOKGrUNypUzHerZ8p_vI',
  // mapKey: 'AIzaSyBO3Aj_uDEPrIoZb9owi11Aewrpy6VW45U',
  // mapKey: 'AIzaSyAegj36IVwknPTbwQrJzbFgT3urf2u7oJ4',
  // mapKey: 'AIzaSyD4dM3hVfGGmT5VkryrtmiD__QHWeFpV0M',
  mapKey: 'AIzaSyCOAhd885zH5TvinKCm3-rhDvgndqzoQyU',
  //webDomain: 'http://localhost:4200/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
