<br>
<div class="static-page-container">
  <div>
    <img *ngIf="!isMobile" class="static-header-image" src="assets/images/list_with_us_banner.jpg" width="100%">
    <img *ngIf="isMobile" class="static-header-image" src="assets/images/list_with_us_banner_mobile.jpg" width="100%">
    <a class="large-btn" style="font-size: 20px;padding: 10px;"
      href="https://docs.google.com/forms/d/e/1FAIpQLScCNsY3akGSEpVwWDb-KyhlZ0dYdlvbs1IxiHHXlOZNvPrVJQ/viewform?usp=sf_link">Let's
      Get Started</a>
  </div>
  <h2>List with Us</h2>
  <p>
    FLEXO™ is a one stop shop for flexible workspaces, co-working and shared offices. Listing with us brings your space
    increased visibility and qualified opportunities.
  </p>
  <p>
    From SMEs to large corporates, we get you the best leads, arrange for site visits and stay involved during
    discussions and negotiations.
  </p>
  <p>
    Listing is 100% free and we only charge for successful referrals.
  </p>
  <p><a class="static-page-links" routerLink="/joy-of-giving">List Your Space With Us Today</a>.</p>
  <br>
  <h3>How can I list my property with FLEXO™?</h3>
  <p style="padding-bottom:  30px;">
    Listing is easy, fast and free. Just drop us a note along with your contact details. Our office space expert with
    reach you and guide you through the process.
  </p>
  <h3>What type of properties can I list?</h3>
  <p>Almost all types of commercial properties that can be used as flexible or shared offices are covered by FLEXO™.
  </p>
  <div class="list-with-us-list">
    <ul style="margin: 0 auto">
      <li>
        &#8226; Coworking spaces
      </li>
      <li>
        &#8226; Business centers
      </li>
      <li>
        &#8226; Serviced/managed offices
      </li>
      <li>
        &#8226; Meeting rooms
      </li>
      <li>
        &#8226; Training / Event spaces
      </li>
      <li>
        &#8226; Virtual office solutions
      </li>
      <li>
        &#8226; Cafes/Restaurants/Pubs/Hotels
      </li>
    </ul>
  </div>
  <br>
  <p><a class="static-page-links" routerLink="/joy-of-giving">List Your Space With Us Today</a>.</p>
</div>