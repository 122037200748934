import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { navigatableComponents, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewMoreDialog } from './details/view-more/view-more.component';
import { LoginDialog } from './login/login-dialog.component';
import { MaterialModule } from './material.module';
import { AppGlobals } from './services/app-globals';
import { MemberService } from './services/member.service';
import { SpaceService } from './services/space.service';
import { FilterDialog } from './shared/component/filter-component/filter-dialog.component';
import { ListItemModule } from './shared/component/list-item/list-item.module';
import { SharedModule } from './shared/shared/shared.module';

import { NgxJsonLdModule } from '@ngx-lite/json-ld';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent, FilterDialog, ...navigatableComponents],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    GoogleMapsModule,
    MatInputModule,
    MatProgressBarModule,
    FormsModule,
    MatRadioModule,
    NgxShimmerLoadingModule,
    MatButtonModule,
    MatListModule,
    MaterialModule,
    MatDialogModule,
    MatStepperModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    ListItemModule,
    NgxJsonLdModule,
    SharedModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  entryComponents: [LoginDialog, FilterDialog, ViewMoreDialog],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    SpaceService,
    MemberService,
    SlickCarouselModule,
    AppGlobals,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
