<div class="trending-place-item">
  <div class="trending-img" *ngIf="!is_similar_listing">
    <ngx-slick-carousel
      class="carousel"
      #slickMainCarousel="slick-carousel"
      [config]="mainSliderConfig"
      responsive="breakpoints"
    >
      <div
        ngxSlickItem
        class="swiper-slide single-listing-slider slide"
        *ngFor="let image of spaceDetails.images"
      >
        <a
          [href]="webDomain + 'coworking-space/' + spaceDetails.link_name"
          target="_blank"
          ><img
            [src]="aws_base_url + spaceDetails.id + '/' + image"
            [alt]="spaceDetails.name"
        /></a>
      </div>
    </ngx-slick-carousel>
    <a
      class="carousel-control-prev"
      style="top: 45%"
      (click)="prev()"
      role="button"
      data-slide="prev"
    >
      <span class="icon ion-chevron-left icon-color"></span>
      <!-- <span class="sr-only">Previous</span> -->
    </a>
    <a
      class="carousel-control-next"
      style="top: 45%"
      (click)="next()"
      role="button"
      data-slide="next"
    >
      <span class="icon ion-chevron-right icon-color"></span>
      <!-- <span class="sr-only">Next</span> -->
    </a>
    <div class="shortlist-share-container">
      <div class="share-btn" style="margin-right: 10px">
        <a
          class="btn"
          [style.color]="spaceDetails.is_shortlisted ? '#F76900' : 'gray'"
          (click)="shortListItem(spaceDetails.id, spaceDetails.is_shortlisted)"
          ><i class="ion-heart"></i>
        </a>
      </div>
      <div class="share-btn">
        <a class="btn"
          ><i class="ion-android-share-alt" style="bottom: 4px"></i
        ></a>
        <ul class="social-share" style="top: 45px; right: -16px">
          <li class="bg-fb" (click)="openFacebook()">
            <a class="share-button"><i class="ion-social-facebook"></i></a>
          </li>
          <li class="bg-tt" (click)="openLinkedIn()">
            <a class="share-button"><i class="ion-social-linkedin"></i></a>
          </li>
          <li class="bg-ig" (click)="openWhatsapp()">
            <a class="share-button"><i class="ion-social-whatsapp"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="trending-img" *ngIf="is_similar_listing">
    <a
      [href]="webDomain + 'coworking-space/' + spaceDetails.link_name"
      target="_blank"
      ><img
        [src]="aws_base_url + spaceDetails.id + '/' + spaceDetails.images[0]"
        alt="..."
    /></a>
  </div>
  <div class="trending-title-box">
    <div class="customer-review" style="margin-bottom: 0px">
      <h2
        (click)="onSpaceNameClicked(spaceDetails)"
        style="font-size: 18px"
        class="h-cursor"
      >
        {{ spaceDetails.name
        }}<span
          style="
            background: unset;
            float: unset;
            margin: unset;
            width: unset;
            height: unset;
            font-size: 15px;
          "
        >
          <i
            class="material-icons"
            style="font-size: 14px; position: relative; top: 2px"
            >location_on</i
          >{{ spaceDetails.landmark }}</span
        >
      </h2>
    </div>
    <div class="rating-summary" style="display: flex">
      <p style="margin-right: 10px; font-size: 11px; align-self: center">
        Space Category
      </p>
      <div class="rating-result" title="60%" *ngIf="spaceDetails.rating">
        <ul class="product-rating">
          <li *ngFor="let element of spaceDetails.rating_array">
            <i class="ion-android-star"></i>
          </li>
          <!-- <li><i class="ion-android-star"></i></li>
          <li><i class="ion-android-star"></i></li> -->
          <li *ngIf="spaceDetails.rating - spaceDetails.rating_floor != 0">
            <i class="ion-android-star-half"></i>
          </li>
          <li *ngFor="let element of spaceDetails.empty_star_array">
            <i class="ion-android-star-outline"></i>
          </li>
        </ul>
      </div>
      <div class="rating-result" title="60%" *ngIf="!spaceDetails.rating">
        <ul class="product-rating">
          <li><i class="ion-android-star-outline"></i></li>
          <li>&nbsp;</li>
          <li>
            <p>Unrated</p>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="filters?.type == null">
      <div class="inventory-label">
        <p>Private Office from</p>
        <p>
          <ng-container *ngIf="spaceDetails?.privatecabin_price">
            <h3 class="price-value">
              Rs. {{ spaceDetails?.privatecabin_price }}/month
            </h3>
          </ng-container>
        </p>
        <p *ngIf="spaceDetails?.privatecabin_price == null">NA</p>
        <a
          *ngIf="spaceDetails?.privatecabin_price == 0"
          href="/contact-form?space_id={{ spaceDetails.id }}"
          target="_blank"
          class="cursor-pointer"
          >Enquire Now</a
        >
      </div>
      <!-- <div class="inventory-label" >
        <p>Private Cabins from</p>
      </div> -->
      <div class="inventory-label">
        <p>Desks from</p>
        <p>
          <ng-container *ngIf="spaceDetails.flexible_desk_price">
            <h3 class="price-value">
              Rs. {{ spaceDetails.flexible_desk_price }}/month
            </h3>
          </ng-container>
        </p>
        <p *ngIf="spaceDetails.flexible_desk_price == null">NA</p>
        <a
          *ngIf="spaceDetails?.flexible_desk_price == 0"
          href="/contact-form?space_id={{ spaceDetails.id }}"
          target="_blank"
          class="cursor-pointer"
          >Enquire Now</a
        >
      </div>
    </div>
    <div *ngIf="filters?.type != null">
      <div class="inventory-label">
        <p>{{ filter_type_name }} from</p>
        <p *ngIf="spaceDetails[filter_type_col_name]">
          <span class="price-value"
            >Rs. {{ spaceDetails[filter_type_col_name] }}</span
          >/{{ filters?.type == 6 || filters?.type == 7 ? "hour" : "month" }}
        </p>
        <p *ngIf="spaceDetails[filter_type_col_name] == null">NA</p>
        <a
          *ngIf="spaceDetails[filter_type_col_name] == 0"
          href="/contact-form?space_id={{ spaceDetails.id }}"
          target="_blank"
          class="cursor-pointer"
          >Enquire Now</a
        >
      </div>
    </div>
    <!-- <div class="inventory-label" >
      <p>Desks from</p>
    </div> -->
    <!-- <p style="font-size: 13px" *ngIf = "spaceDetails.customized_space_price || spaceDetails.flexible_desk_price || spaceDetails.virtual_office_price || spaceDetails.meeting_room_price || spaceDetails.training_room_price || spaceDetails?.privatecabin_price || spaceDetails.desks_price"> -->
    <div class="description">
      <h3
        *ngIf="is_see_more_visible"
        class="less-content"
        style="font-size: 12px"
      >
        {{ spaceDetails?.about }}
      </h3>
      <a
        style="font-size: 12px; font-weight: 600; cursor: pointer"
        *ngIf="is_see_more_visible"
        (click)="toggleSeeMore($event, 0)"
        >See more</a
      >
      <p *ngIf="!is_see_more_visible">{{ spaceDetails?.about }}</p>
      <a
        style="font-size: 12px; font-weight: 600; cursor: pointer"
        *ngIf="!is_see_more_visible"
        (click)="toggleSeeMore($event, 1)"
        >See less</a
      >
    </div>
    <!--  <div class="available-resources">
      <div
        [hidden]="!(!is_similar_listing && (spaceDetails.customized_space_price != null || spaceDetails.desks_price != null || spaceDetails.virtual_office_price != null || spaceDetails.meeting_room_price != null || spaceDetails.training_room_price != null))">
        <span>Also Available:&nbsp;</span>
        <span *ngIf="spaceDetails.customized_space_price != null">Customized Space,&nbsp;</span>
        <span *ngIf="spaceDetails.desks_price != null">Dedicated Desk,&nbsp;</span>
        <span *ngIf="spaceDetails.virtual_office_price != null">Virtual Office,&nbsp;</span>
        <span *ngIf="spaceDetails.meeting_room_price != null">Meeting Room&nbsp;</span>
        <span *ngIf="spaceDetails.training_room_price != null">Training Room/Event Space</span>
      </div>
    </div> -->
    <hr style="margin-top: 5px" *ngIf="!is_similar_listing" />
    <div class="commute-details" *ngIf="!is_similar_listing">
      <div class="commute-details-item">
        <div class="commute-label">
          <i class="material-icons">subway</i>
          <p>Metro</p>
        </div>
        <div class="commute-value">
          <p>{{ spaceDetails.near_by_metro || "NA" }}</p>
          <p>
            {{
              spaceDetails.metro_distance
                ? spaceDetails?.metro_distance + " kms"
                : "NA"
            }}
          </p>
        </div>
      </div>
      <div class="commute-details-item">
        <div class="commute-label">
          <i class="material-icons">train</i>
          <p>Railway</p>
        </div>
        <div class="commute-value">
          <p>{{ spaceDetails.near_by_railway || "NA" }}</p>
          <p>
            {{
              spaceDetails.railway_distance
                ? spaceDetails?.railway_distance + " kms"
                : "NA"
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- <ul class="trending-address">
        <li>


        </li>
        <li>


        </li>
      </ul> -->

    <!-- <div class="trending-bottom mar-top-15 pad-bot-30">
        <div class="trend-left float-left">
          <span class="round-bg pink"><i class="icofont-hotel"></i></span>
          <p><a href="#">Hotel</a></p>

        </div>
        <div class="trend-right float-right">
          <div class="trend-open"><i class="ion-clock"></i>
            Open <p>till 11.00pm</p>
          </div>
        </div>
      </div> -->
  </div>
</div>
