import { Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { HomeComponent } from './home/home.component';
import { JoyOfGivingComponent } from './joy-of-giving/joy-of-giving.component';
import { ListWithUsComponent } from './list-with-us/list-with-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { _404Component } from './shared/shared/_404/_404.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

export const routes: Routes = [
  {
    path: 'coworking-space',
    loadChildren: () =>
      import('./details/details.module').then((m) => m.DetailsModule),
  },
  {
    path: 'in/coworking',
    loadChildren: () =>
      import('./city-listing/city-listing.module').then(
        (m) => m.CityListingModule
      ),
  },
  {
    path: 'in/coworking-space',
    loadChildren: () =>
      import('./area-listing/area-listing.module').then(
        (m) => m.AreaListingModule
      ),
  },
  {
    path: 'contact-form',
    loadChildren: () =>
      import('./contact-form/contact-form.module').then(
        (m) => m.ContactFormModule
      ),
  },
  {
    path: 'my-shortlists',
    loadChildren: () =>
      import('./shortlisted-spaces/shortlisted-spaces.module').then(
        (m) => m.ShortlistedSpacesModule
      ),
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'joy-of-giving',
    component: JoyOfGivingComponent,
  },
  {
    path: 'careers',
    component: CareersComponent,
  },
  {
    path: 'enterprise',
    component: EnterpriseComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
  },
  {
    path: 'list-with-us',
    component: ListWithUsComponent,
  },

  {
    path: 'in/coworking-space/delhi',
    redirectTo: '/in/coworking/new-delhi',
    pathMatch: 'full',
  },

  { path: '**', component: _404Component },
];

export const navigatableComponents = [
  HomeComponent,
  AboutComponent,
  ContactComponent,
  JoyOfGivingComponent,
  EnterpriseComponent,
  CareersComponent,
  TermsConditionsComponent,
  PrivacyPolicyComponent,
  ListWithUsComponent,
];
