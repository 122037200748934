import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class SpaceService {
  public faqsSubject = new BehaviorSubject<any[]>([]);
  public faqs = this.faqsSubject.asObservable().pipe(distinctUntilChanged());
  constructor(private http: HttpClient) {}
  get getFaqs(): any {
    return this.faqsSubject.value || [];
  }
  private base_url = environment.apiUrl + `api/v1/managers/`;
  private validateSessionUrl = this.base_url + 'validateSession';
  private getAllCitiesUrl = environment.apiUrl + `api/v1/getAllCities`;
  getCityAndLocationInfo =
    environment.apiUrl + `api/v1/spaces/getCityAndLocationInfo`;

  private getSpacesByCityUrl =
    environment.apiUrl + `api/v1/spaces/getSpacesByCity`;
  private addBookingDetailsUrl =
    environment.apiUrl + 'api/v1/common/addBookingAndSendInvoice';
  private checkAvailabilityUrl =
    environment.apiUrl + 'api/v1/common/check_availability';
  private getSpaceConfigDetailsUrl =
    environment.apiUrl + 'api/v1/app/user/spaceConfig';
  private getInRadiusUrl = environment.apiUrl + 'api/v1/spaces/allInRadius';
  private getSpaceDetailsUrl = environment.apiUrl + 'api/v1/spaces/details';
  private getAllTrendingUrl =
    environment.apiUrl + 'api/v1/spaces/getAllTrending';
  private getAllSitemapsUrl = environment.apiUrl + `api/v1/get_sitemaps`;
  private getShortlistSpacesUrl =
    environment.apiUrl + `api/v1/spaces/getShortlistSpaces`;

  private fetchNearbySpaces =
    environment.apiUrl + `api/v1/spaces/fetchNearbySpaces`;

  getQuestionByRadiusUrl =
    environment.apiUrl + `api/v1/faqs/getQuestionByRadius`;

  getQuestionByLocationNameUrl =
    environment.apiUrl + `api/v1/faqs/getQuestionByLocationName`;
  getFaqsBySpaceIdUrl = environment.apiUrl + `api/v1/faqs/getFaqsBySpaceId`;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  setFaqs(faqs) {
    console.log({ faqs });
    this.faqsSubject.next(faqs);
  }
  validateSession(): Promise<any> {
    return this.http
      .get(this.validateSessionUrl, {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpaceConfigDetails(subdomain): Promise<any> {
    return this.http
      .get(this.getSpaceConfigDetailsUrl + '?subdomain=' + subdomain, {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getCities(): Promise<any> {
    return this.http
      .get(this.getAllCitiesUrl, { headers: this.headers })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSitemaps(): Promise<any> {
    return this.http
      .get(this.getAllSitemapsUrl, { headers: this.headers })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getShortlistedSpaces(space_ids): Promise<any> {
    return this.http
      .post(this.getShortlistSpacesUrl, JSON.stringify({ space_ids }), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  addBookingAndCreateInvoice(booking_details, invoice_details): Promise<any> {
    return this.http
      .post(
        this.addBookingDetailsUrl,
        JSON.stringify({ booking_details, invoice_details }),
        { headers: this.headers, withCredentials: true }
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpacesByCity(params, page_no): Promise<any> {
    params.page_no = page_no;
    return this.http
      .post(this.getSpacesByCityUrl, JSON.stringify(params), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.setFaqs(res.faqs);
        return res;
      })
      .catch(this.handleError);
  }

  checkAvailability(start_date, location_id): Promise<any> {
    return this.http
      .get(
        this.checkAvailabilityUrl +
          `?location_id=${location_id}&start_date=${start_date}`,
        { headers: this.headers }
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getInRadius(params, page_no): Promise<any> {
    let data = {
      lat: params.area_lat || params.user_lat,
      longi: params.area_long || params.user_long,
      type: params.type,
      is_daypass_available: params.day_pass,
      page_no,
      capacity: params.capacity,
      radius: params.radius,
      price: params.price,
      amenities: params.amenities,
      min_price: params.min_price,
      max_price: params.max_price,
    };
    return this.http
      .post(this.getInRadiusUrl, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getSpaceDetails(space_id): Promise<any> {
    return this.http
      .get(this.getSpaceDetailsUrl + '?space_id=' + space_id, {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getAllTrending(data): Promise<any> {
    return this.http
      .post(this.getAllTrendingUrl, JSON.stringify(data), {
        headers: this.headers,
        withCredentials: true,
      })
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  getLatlong(address): Promise<any> {
    return this.http
      .get(
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          address +
          `&key=${environment.mapKey}`
      )
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }
  getNearBySpaces(cityName): Observable<any> {
    return this.http.get(
      environment.apiUrl + 'api/v1/spaces/getNearBySpacesByCityId/' + cityName,
      {
        headers: this.headers,
      }
    );
  }

  getCityInfo(lat, long): Observable<any> {
    return this.http.post(`${this.getCityAndLocationInfo}`, { lat, long });
  }

  getNearbySpaces(keyword, location, radius, type): Observable<any> {
    return this.http.post(`${this.fetchNearbySpaces}`, {
      keyword,
      location,
      radius,
      type,
    });
  }

  getQuestionByRadius(lat, longi): Observable<any> {
    return this.http.post(`${this.getQuestionByRadiusUrl}`, { lat, longi });
  }

  getQuestionByLocationName(location): Observable<any> {
    return this.http
      .get(`${this.getQuestionByLocationNameUrl}/${location}`)
      .pipe(
        map((data) => {
          console.log(data);
          this.setFaqs(data);
        })
      );
  }

  getFaqsBySpaceId(spaceId): Observable<any> {
    return this.http.get(`${this.getFaqsBySpaceIdUrl}/${spaceId}`).pipe(
      map((data) => {
        console.log(data);
        this.setFaqs(data);
      })
    );
  }
}
