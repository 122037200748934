import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GlobalVariables } from '../../../global/global-variables';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css'],
})
export class ListItemComponent implements OnInit {
  public loadAPI: Promise<any>;
  public aws_base_url =
    'https://s3.ap-south-1.amazonaws.com/' +
    environment.s3_bucket_path +
    '/details_images/';
  webDomain = environment.webDomain;
  constructor() {}

  @Input() spaceDetails;
  @Input() isMobile;
  @Input() filters;
  @Input() is_similar_listing: boolean;

  @Output() shortlistItemEvent = new EventEmitter<any>();
  public resource_types = GlobalVariables.resource_types;
  public filter_type_name;
  public is_see_more_visible = 1;
  public filter_type_col_name;
  ngOnInit(): void {
    if (this.filters && this.filters.type != null) {
      this.filter_type_name = this.resource_types.find(
        (x) => x.id == this.filters.type
      ).name;
      this.filter_type_col_name = this.resource_types.find(
        (x) => x.id == this.filters.type
      ).column_name;
    }
  }

  @ViewChild('slickMainCarousel', { static: false })
  slickMainCarousel: SlickCarouselComponent;
  public mainSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    dots: false,
    centerPadding: '0',
    variableHeight: false,
    autoplay: false,
    swipeToSlide: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          variableHeight: false,
        },
      },
    ],
  };

  next() {
    this.slickMainCarousel.slickNext();
  }

  prev() {
    this.slickMainCarousel.slickPrev();
  }

  toggleSeeMore(e: Event, value) {
    e.stopPropagation();
    this.is_see_more_visible = value;
  }

  openDetailsPage() {
    window.open('coworking-space/' + this.spaceDetails.link_name, '_blank');
  }

  openFacebook() {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' +
        window.location.origin +
        '/coworking-space/' +
        this.spaceDetails.link_name.toLowerCase(),
      'facebook-popup',
      'height=350,width=600'
    );
  }

  openLinkedIn() {
    window.open(
      'https://www.linkedin.com/shareArticle?mini=true&url=' +
        window.location.origin +
        '/coworking-space/' +
        this.spaceDetails.link_name.toLowerCase() +
        '&title=Share%Spaces',
      'linkedin-popup',
      'height=350,width=600'
    );
  }

  openWhatsapp() {
    let staticText = 'Checkout this space on FLEXO ';
    if (!this.isMobile) {
      window.open(
        `https://web.whatsapp.com/send?text=${staticText}-${window.location.origin}/coworking-space/` +
          this.spaceDetails.link_name.toLowerCase(),
        'whatapp-popup',
        'height=650,width=650'
      );
    } else {
      window.open(
        `whatsapp://send?text=${staticText}-${window.location.origin}/coworking-space/${this.spaceDetails.link_name}`
      );
    }
  }

  shortListItem(id, is_shortlisted) {
    this.shortlistItemEvent.emit({ id, is_shortlisted });
  }
  onSpaceNameClicked(e: any) {
    window.open(
      `${this.webDomain}coworking-space/` + this.spaceDetails.link_name,
      '_blank'
    );
  }
}
