<div class="privacy-content">
  <!--section -->
  <section class="parallax-section" data-scrollax-parent="true">
    <div class="bg1 par-elem " data-scrollax="properties: { translateY: '30%' }"
      style="background-color: gray; transform: translateZ(0px) translateY(-4.22535%);">
    </div>
    <!-- <img class="bg1 par-elem " src="assets/images/Contact.jpg" alt=""
      style="transform: translateZ(0px) translateY(-4.22535%);"> -->
    <div class="privacy-container">
      <div class="col-md-8 offset-md-2 text-center">
        <h2 class="section-title v1" style="color: white;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 0px;
        padding-bottom: 0px;">PRIVACY POLICY</h2>
      </div>
    </div>
  </section>
  <section class="gray-section" id="sec1">
    <div class="privacy-container">
      <div class="row">
        <div class="col-md-12">
          <div class="list-single-main-wrapper fl-wrap" id="sec2">
            <!-- article> -->
            <article>
              <div style="text-align: left;">
                <p>
                  Flexo Spaces recognises the importance of maintaining your privacy. We value your privacy and
                  appreciate your trust in us. This Privacy Policy sets out how Flexo Spaces uses and protects any
                  information that you give Flexo Spaces when you use this www.flexospaces.com or the Flexo
                  Spaces mobile application or any other digital medium and other offline sources of our Company. Flexo
                  Spaces is committed to ensure that your privacy is protected. Should we ask you to provide certain
                  information by which you can be identified when using this website, then you can be assured that it
                  will only be used in accordance with this Privacy Policy as it describes how we treat user information
                  we collect from you, the policies and procedures on the collection, use, disclosure and protection of
                  your information when you use our Flexo Spaces Platform.
                </p>
                <p>
                  This Privacy Policy applies to current and former visitors to our Flexo Spaces Platform and to our
                  online customers. By visiting and/or using our website, you agree to this Privacy Policy. Flexo
                  Spaces may change this policy from time to time by updating this page. You should check this page from
                  time to time to ensure that you are happy with any changes.
                </p>
                <p>
                  www.flexospaces.com is a property of Flexo Spaces established under laws of India, having its
                  registered office at L3A/102 Oakland Park, Yamuna Nagar, Andheri West, Mumbai, Maharashtra - 400053.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    DEFINITIONS:
                  </h3>
                </div>
                <p>
                  The terms "Flexo", "Company", "we", "us" and "our" refer to the Flexo Spaces.
                </p>
                <p>
                  The term "Flexo Spaces Platform" refers to www.flexospaces.com or the Flexo Spaces mobile application
                  or any other digital medium and other offline sources.
                </p>
                <p>
                  The terms "you" and "your" refer to the user of the www.flexospaces.com.
                </p>
                <p>
                  The term "Group" means, with respect to any person, any entity that is controlled by such person, or
                  any entity that controls such person, or any entity that is under common control with such person,
                  whether directly or indirectly, or, in the case of a natural person, any Relative (as such term is
                  defined in the Companies Act, 1956 and Companies Act, 2013 to the extent applicable) of such person.
                </p>
                <p>
                  The term "Services" refers to any services offered by Flexo Spaces whether on the Swiggy Platform or
                  otherwise.
                </p>
                <p>
                  The term "Policy" refers to this Privacy Policy.
                </p>
                <p>
                  Please read this Policy before using the Flexo Spaces Platform or submitting any personal information
                  to Flexo Spaces. This Policy is a part of and incorporated within, and is to be read along with, the
                  Terms of Use www.flexospaces.com/termsofuse.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    INFORMATION WE COLLECT FROM YOU:
                  </h3>
                </div>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Contact information:
                  </h3>
                </div>
                <p>
                  We might collect your .
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Payment and billing information:
                  </h3>
                </div>
                <p>
                  We might collect your billing name, billing address and payment method when you buy a product/service.
                  We NEVER collect your credit card number or credit card expiry date or other details pertaining to
                  your credit card on our website. Credit card information will be obtained and processed by our online
                  payment partner.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Information you post:
                  </h3>
                </div>
                <p>
                  We collect information you post in a public space on our Flexo Spaces Platform or on a third-party
                  social media site belonging to Flexo Spaces.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Usage and Preference Information:
                  </h3>
                </div>
                <p>
                  We collect information as to how you interact with our Services, preferences expressed and settings
                  chosen. Flexo Spaces Platform includes the Flexo Spaces advertising services ("Ad Services"), which
                  may collect user activity and browsing history within the Flexo Spaces Platform and across third-party
                  sites and online services, including those sites and services that include our ad pixels ("Pixels"),
                  widgets, plug-ins, buttons, or related services or through the use of cookies. Our Ad Services collect
                  browsing information including, without limitation, your Internet protocol (IP) address and location,
                  your login information, browser type and version, date and time stamp, user agent, Flexo Spaces cookie
                  ID (if applicable), time zone setting, browser plug-in types and versions, operating system and
                  platform, and other information about user activities on the Flexo Spaces Platform, as well as on
                  third party sites and services that have embedded our Pixels, widgets, plug-ins, buttons, or related
                  services.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Transaction Information:
                  </h3>
                </div>
                <p>
                  We collect transaction details related to your use of our Services, and information about your
                  activity on the Services, including the full Uniform Resource Locators (URL), the type of Services you
                  requested or provided, comments, domain names, search results selected, number of clicks, information
                  and pages viewed and searched for, the order of those pages, length of your visit to our Services, the
                  date and time you used the Services, amount charged, details regarding application of promotional
                  code, methods used to browse away from the page and any phone number used to call our customer service
                  number and other related transaction details.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Stored information and files:
                  </h3>
                </div>
                <p>
                  Flexo Spaces mobile application (Flexo Spaces app) may also access metadata and other information
                  associated with other files stored on your mobile device. This may include, for example, photographs,
                  audio and video clips, personal contacts and address book information. If you permit the Flexo
                  Spaces app to access the address book on your device, we may collect names and contact information
                  from your address book to facilitate social interactions through our services and for other purposes
                  described in this Policy or at the time of consent or collection. If you permit the Flexo Spaces app
                  to access the calendar on your device, we collect calendar information such as event title and
                  description, your response (Yes, No, Maybe), date and time, location and number of attendees. If you
                  are a partner company, we will, additionally, record your calls with us made from the device used to
                  provide Services, related call details, SMS details location and address details.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Demographic information:
                  </h3>
                </div>
                <p>
                  We may collect demographic information about you, events you like, events you intend to participate
                  in, tickets you buy, or any other information provided by you during the use of Flexo Spaces Platform.
                  We might collect this as a part of a survey also.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    Other information:
                  </h3>
                </div>
                <p>
                  If you use Flexo Spaces Platform, we may collect information about your IP address and the browser
                  you're using. We might look at what site you came from, duration of time spent on our website, pages
                  accessed or what site you visit when you leave us. We might also collect the type of mobile device you
                  are using, or the version of the operating system your computer or device is running.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    WAYS OF COLLECTING INFORMATION:
                  </h3>
                </div>
                <p>
                  We collect information directly from you:
                </p>
                <ul>
                  <li>
                    <p>
                      We collect information directly from you when you register on Flexo Spaces Platform. We also
                      collect information if you post a comment on Flexo Spaces Platform or ask us a question through
                      phone or email or any other source of communication.
                    </p>
                  </li>
                </ul>

                <p>
                  We collect information from you passively:
                </p>
                <ul>
                  <li>
                    <p>
                      We use tracking tools like for collecting information about your usage of our website.
                    </p>
                  </li>
                </ul>
                <p>
                  We get information about you from third parties:
                </p>
                <ul>
                  <li>
                    <p>
                      We may receive information about you from third parties, such as other users, partners (including
                      ad partners, analytics providers, search information providers), or our affiliated companies or if
                      you use any of the other websites/apps we operate or the other Services we provide. Users of our
                      Ad Services and other third-parties may share information with us such as the cookie ID, device
                      ID, or demographic or interest data, and information about content viewed or actions taken on a
                      third-party website, online services or apps. For example, users of our Ad Services may also be
                      able to share customer list information (e.g., email or phone number) with us to create customized
                      audience segments for their ad campaigns.
                    </p>
                  </li>
                  <li>
                    <p>
                      When you sign in to Flexo Spaces Platform with your SNS account, or otherwise connect to your SNS
                      account with the Services, you consent to our collection, storage, and use, in accordance with
                      this Policy, of the information that you make available to us through the social media interface.
                      This could include, without limitation, any information that you have made public through your
                      social media account, information that the social media service shares with us, or information
                      that is disclosed during the sign-in process. Please see your social media provider’s privacy
                      policy and help center for more information about how they share information when you choose to
                      connect your account.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you are partner company, we may, additionally, receive feedback and rating from other users.
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    USE OF YOUR PERSONAL INFORMATION:
                  </h3>
                </div>
                <p>
                  We use information to contact you:
                </p>
                <ul>
                  <li>
                    <p>
                      We might use the information you provide to contact you for confirmation of a purchase on Flexo
                      Spaces Platform or for other promotional purposes.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information to abide by the contracts:
                </p>
                <ul>
                  <li>
                    <p>
                      We might use the information you provide to carry out our obligations arising from any contracts
                      entered into between you and us and to provide you with the relevant information and services.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information to respond to your requests or questions:
                </p>
                <ul>
                  <li>
                    <p>
                      We might use your information to confirm your registration for rendering services we offer.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information to improve our products and services:
                </p>
                <ul>
                  <li>
                    <p>
                      We might use your information to customize your experience with us. This could include displaying
                      content based upon your preferences. We might also use your information to understand our users
                      (what they do on our Services, what features they like, how they use them, etc.), improve the
                      content and features of our Services (such as by personalizing content to your interests), process
                      and complete your transactions, make special offers, provide customer support, process and respond
                      to your queries and to generate and review reports and data about, and to conduct research on, our
                      user base and Service usage patterns. If you are a partner company, we may use the information to
                      track the progress of your service to our customers.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information to look at site trends and customer interests:
                </p>
                <ul>
                  <li>
                    <p>
                      We may use your information to make our website and products better. We may combine information we
                      get from you with information about you we get from third parties. We may use your information to
                      provide you with information about services we consider similar to those that you are already
                      using, or have enquired about, or may interest you. If you are a registered user, we will contact
                      you by electronic means (e-mail or SMS or telephone) with information about these services.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information for security purposes:
                </p>
                <ul>
                  <li>
                    <p>
                      We may use information to protect our company, our customers, or our Flexo Spaces Platform.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information for marketing purposes:
                </p>
                <ul>
                  <li>
                    <p>
                      We might send you information about special promotions or offers. We might also tell you about new
                      features or products or enroll you in our newsletter. These might be our own offers or products,
                      or third-party offers or products we think you might find interesting. We may use the information
                      to measure or understand the effectiveness of advertising we serve to you and others, and to
                      deliver relevant advertising to you.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information to send you transactional communications:
                </p>
                <ul>
                  <li>
                    <p>
                      We might send you emails or SMS about your account or purchase of our product.
                    </p>
                  </li>
                </ul>
                <p>
                  We use information as otherwise permitted by law:
                </p>
                <ul>
                  <li>
                    <p>
                      We may combine the information that we receive from third parties with the information you give to
                      us and information we collect about you for the purposes set out above. Further, we may anonymize
                      and/or de-identify information collected from you through the Services or via other means,
                      including via the use of third-party web analytic tools. As a result, our use and disclosure of
                      aggregated and/or de-identified information is not restricted by this Policy, and it may be used
                      and disclosed to others without limitation.
                    </p>
                  </li>
                  <li>
                    <p>
                      We analyse the log files of our Flexo Spaces Platform that may contain Internet Protocol (IP)
                      addresses, browser type and language, Internet service provider (ISP), referring, app crashes,
                      page viewed and exit websites and applications, operating system, date/time stamp, and clickstream
                      data. This helps us to administer the website, to learn about user behavior on the site, to
                      improve our product and services, and to gather demographic information about our user base as a
                      whole.
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    SHARING/DISCLOSURE/DISTRIBUTION OF YOUR INFORMATION WITH THIRD-PARTIES:
                  </h3>
                </div>
                <p>
                  We will share information with third parties who perform services on our behalf:
                </p>
                <ul>
                  <li>
                    <p>
                      We may share your information with our vendors, consultants, marketing partners, research firms
                      and other service providers or business partners, such as payment processing companies, to support
                      our business. For example, your information may be shared with outside vendors to send you emails
                      and messages or push notifications to your devices in relation to our Services, to help us analyze
                      and improve the use of our Services, to process and collect payments.
                    </p>
                  </li>
                  <li>
                    <p>
                      We may also use vendors for other projects, such as conducting surveys or organizing sweepstakes
                      for us. Some vendors may be located outside of India.
                    </p>
                  </li>
                </ul>
                <p>
                  We will share information with Advertisers and advertising networks:
                </p>
                <ul>
                  <li>
                    <p>
                      We may work with third parties such as network advertisers to serve advertisements on the Flexo
                      Spaces Platform and on third-party websites or other media (e.g., social networking platforms).
                      These third parties may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs
                      and other tracking technologies to measure the effectiveness of their ads and to personalize
                      advertising content to you.
                    </p>
                  </li>
                  <li>
                    <p>
                      While you cannot opt out of advertising on the Flexo Spaces Platform, you may opt out of much
                      interest-based advertising on third party sites and through third party ad networks (including
                      DoubleClick Ad Exchange, Facebook Audience Network and Google AdSense). Opting out means that you
                      will no longer receive personalized ads by third parties ad networks from which you have opted
                      out, which is based on your browsing information across multiple sites and online services. If you
                      delete cookies or change devices, your ‘opt out’ may no longer be effective to fulfil the purpose
                      for which you provide it.
                    </p>
                  </li>
                </ul>
                <p>
                  We will share information with our business partners:
                </p>
                <ul>
                  <li>
                    <p>
                      This includes a third party who provide us various services. Our partners use the information we
                      give them as described in their privacy policies.
                    </p>
                  </li>
                  <li>
                    <p>
                      While you place a request to order through the Flexo Spaces Platform, your information is provided
                      to us and to the vendors with whom you may choose to order. In order to facilitate your
                      online order processing, we provide your information to that vendors in a similar manner as if you
                      had made a goods/products/food order directly with the vendors. If you provide a mobile phone
                      number, Flexo Spaces may send you text messages regarding the orders delivery status.
                    </p>
                  </li>
                </ul>
                <p>
                  We will share information with Other Users:
                </p>
                <ul>
                  <li>
                    <p>
                      If you are a delivery partner, we may share your name, phone number and/or profile picture (if
                      applicable), tracking details with other users to provide them the Services.
                    </p>
                  </li>
                </ul>
                <p>
                  We may share information if we think we have to in order to comply with the law or to protect
                  ourselves:
                </p>
                <p>
                  We may also share it if a government agency or investigatory body or other companies assisting us,
                  requests when we are:
                </p>
                <ul>
                  <li>
                    <p>
                      Obligated under the applicable laws or in good faith to respond to court orders and processes; or
                    </p>
                  </li>
                  <li>
                    <p>
                      Detecting and preventing against actual or potential occurrence of identity theft, fraud, abuse of
                      Services and other illegal acts;
                    </p>
                  </li>
                  <li>
                    <p>
                      Responding to claims that an advertisement, posting or other content violates the intellectual
                      property rights of a third party;
                    </p>
                  </li>
                  <li>
                    <p>
                      Under a duty to disclose or share your personal data in order to enforce our Terms of Use and
                      other agreements, policies or to protect the rights, property, or safety of the Company, our
                      customers, or others, or in the event of a claim or dispute relating to your use of our Services.
                      This includes exchanging information with other companies and organisations for the purposes of
                      fraud detection and credit risk reduction.
                    </p>
                  </li>
                </ul>
                <p>
                  We may share information for Internal Use or may share it with any successor to all or part of our
                  business:
                </p>
                <ul>
                  <li>
                    <p>
                      For example, if part of our business is sold we may give our customer list as part of that
                      transaction. We may also share your information with any present or future member of our "Group"
                      (as defined above) or affiliates for our internal business purposes
                    </p>
                  </li>
                </ul>
                <p>
                  We may share your information for reasons not described in this policy:
                </p>
                <ul>
                  <li>
                    <p>
                      We may share your information other than as described in this Policy if we notify you and you
                      consent to the sharing. We will tell you before we do this.
                    </p>
                  </li>
                </ul>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    YOUR CONSENT:
                  </h3>
                </div>
                <p>
                  By using the Flexo Spaces Platform and the Services provided therein, you agree and consent to the
                  collection, transfer, use, storage, disclosure and sharing of your information as described and
                  collected by us in accordance with this Policy. If you do not agree with the Policy, please do not use
                  or access the Flexo Spaces Platform.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    COOKIES:
                  </h3>
                </div>
                <p>
                  Our Flexo Spaces Platform and third parties with whom we partner, may use cookies, pixel tags, web
                  beacons, mobile device IDs, "flash cookies" and similar files or technologies to collect and store
                  information with respect to your use of the Services and third-party websites.
                </p>
                <p>
                  Cookies are small files that are stored on your browser or device by websites, apps, online media and
                  advertisements. We use cookies and similar technologies for purposes such as:
                </p>
                <ul>
                  <li>
                    <p>
                      Authenticating users;
                    </p>
                  </li>
                  <li>
                    <p>
                      Remembering user preferences and settings;
                    </p>
                  </li>
                  <li>
                    <p>
                      Determining the popularity of content;
                    </p>
                  </li>
                  <li>
                    <p>
                      Delivering and measuring the effectiveness of advertising campaigns;
                    </p>
                  </li>
                  <li>
                    <p>
                      Analysing site traffic and trends, and generally understanding the online behaviours and interests
                      of people who interact with our services.
                    </p>
                  </li>
                </ul>
                <p>
                  A pixel tag (also called a web beacon or clear GIF) is a tiny graphic with a unique identifier,
                  embedded invisibly on a webpage (or an online ad or email), and is used to count or track things like
                  activity on a webpage or ad impressions or clicks, as well as to access cookies stored on users’
                  computers. We use pixel tags to measure the popularity of our various pages, features and services. We
                  also may include web beacons in e-mail messages or newsletters to determine whether the message has
                  been opened and for other analytics.
                </p>
                <p>
                  To modify your cookie settings, please visit your browser’s settings. By using our Services with your
                  browser settings to accept cookies, you are consenting to our use of cookies in the manner described
                  in this section.
                </p>
                <p>
                  We may also allow third parties to provide audience measurement and analytics services for us, to
                  serve advertisements on our behalf across the Internet, and to track and report on the performance of
                  those advertisements. These entities may use cookies, web beacons, SDKs and other technologies to
                  identify your device when you visit the Flexo Spaces Platform and use our Services, as well as when
                  you visit other online sites and services.
                </p>
                <p>
                  Please see our Cookie Policy here for more information regarding the use of cookies and other
                  technologies described in this section, including regarding your choices relating to such
                  technologies.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    DATA SECURITY PRECAUTIONS:
                  </h3>
                </div>
                <p>
                  We have in place appropriate technical and security measures to secure the information collected by
                  us. We use vault and tokenization services from third party service providers to protect the sensitive
                  personal information provided by you. The third-party service providers with respect to our vault and
                  tokenization services and our payment gateway and payment processing are compliant with the payment
                  card industry standard (generally referred to as PCI compliant service providers). You are advised not
                  to send your full credit/debit card details through unencrypted electronic platforms. Where we have
                  given you (or where you have chosen) a username and password which enables you to access certain parts
                  of the Flexo Spaces Platform, you are responsible for keeping these details confidential. We ask you
                  not to share your password with anyone.
                </p>
                <p>
                  Please be aware that the transmission of information via the internet is not completely secure.
                  Although we will do our best to protect your personal data, we cannot guarantee the security of your
                  data transmitted through the Flexo Spaces Platform. Once we have received your information, we will
                  use strict physical, electronic, and procedural safeguards to try to prevent unauthorised access.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    EMAIL OPT-OUT:
                  </h3>
                </div>
                <p>
                  You can opt out of receiving our marketing emails. To stop receiving our promotional emails, please
                  email (hello@flexospaces.com). Even if you opt out of getting marketing messages, we will still be
                  sending you transactional messages through email and SMS about your purchases.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    THIRD PARTY SITES:
                  </h3>
                </div>
                <p>
                  The Flexo Spaces Platform may contain links to other websites. If you click on one of the links to
                  third party websites, you may be taken to websites we do not control. Any personal information about
                  you collected whilst visiting such websites is not governed by this Policy. Flexo Spaces shall not be
                  responsible for and has no control over the practices and content of any website accessed using the
                  links contained on the Flexo Spaces Platform. Read the privacy policy of other websites carefully as
                  this policy does not apply to the privacy practices of those websites. We are not responsible for
                  these third party sites.
                </p>
                <p>
                  This Policy shall also not apply to any information you may disclose to any of our service
                  providers/service personnel which we do not require you to disclose to us or any of our service
                  providers under this Policy.
                </p>
                <div class="list-single-main-item-title-1 fl-wrap terms">
                  <h3>
                    JURISDICTION:
                  </h3>
                </div>
                <p>
                  If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy
                  and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy
                  shall be governed by the laws of India.
                </p>
                <p>
                  Further, please note that the Flexo Spaces Platform stores your data with the cloud platform
                  of provided by Amazon Web Services, which may store this data on their servers and has security
                  measures in place to protect the loss, misuse and alteration of the information, details of which are
                  available on their website.
                </p>
                <p>
                  In the event you have questions or concerns about the security measures adopted by Amazon Web
                  Services, you can contact their data protection/ privacy team/ legal team or designated grievance
                  officer at these organisations, whose contact details are available in its privacy policy, or can also
                  write to our Grievance Officer at the address provided above.
                </p>
                <p>
                  If you have any queries relating to the processing or usage of information provided by you in
                  connection with this Policy or other privacy concerns, you can email us at hello@flexospaces.com.
                </p>
              </div>
            </article>
          </div>
        </div>
        <!--box-widget-wrap -->
      </div>
    </div>
  </section>
</div>