<!-- <p>enterprise works!</p> -->
<br>
<div class="enterprise-container">
  <div>
    <img *ngIf="!isMobile" class="header-image" src="assets/images/enterprise_banner.jpg" width="100%">
    <img *ngIf="isMobile" class="header-image" src="assets/images/enterprise_banner_mobile.jpg" width="100%">
    <a class="large-btn" style="font-size: 20px;padding: 10px;"
      href="https://docs.google.com/forms/d/e/1FAIpQLScCNsY3akGSEpVwWDb-KyhlZ0dYdlvbs1IxiHHXlOZNvPrVJQ/viewform?usp=sf_link">ENQUIRE
      NOW</a>
  </div>
  <h2>Adapt to the New Normal</h2>
  <p style="width: 90%; margin: 0 auto;">
    Make informed decisions on your workplace strategy with Flexo. We help you with more than just options because your
    business deserves to save more and grow more.
    We provide tailor-made workplace solutions that match your exact needs.

  </p><br>
  <!-- <h3>Our office space experts help you with:</h3>
  <p style="padding-bottom:  30px;">
    Founded in the pandemic of 2020, Flexo is rooted in the philosophy of giving back. We believe that one of the ways
    to repair and rebuild the economy is to give.
  </p> -->
  <div class="flex-container">
    <div class="flex-images">
      <img src="assets/images/our_experts_can_help_you_with.jpg" alt="">
    </div>
    <div>
      <p class="sub-heading">Our Office Space Experts Help you with</p><br>
      <ul>
        <li>
          &#8226; Customized spaces for small and large teams
        </li>
        <li>
          &#8226; Distributed workspaces for the new normal
        </li>
        <li>
          &#8226; Hub and spoke workplace strategy
        </li>
        <li>
          &#8226; Branded and bespoke workspaces
        </li>
        <li>
          &#8226; Well-equipped and managed spaces
        </li>
        <li>
          &#8226; Plug-and-play setups
        </li>
        <li>
          &#8226; Touchdown offices
        </li>
      </ul>
    </div>
  </div><br>
  <div class="flex-container-reverse">
    <div>
      <p class="sub-heading">Why go with FLEXO™?</p><br>
      <ul>
        <li>
          &#8226; Free, fast and easy
        </li>
        <li>
          &#8226; Wide range of office spaces to choose from
        </li>
        <li>
          &#8226; One stop shop for customized workspace requirements
        </li>
        <li>
          &#8226; Specialist in flexible office spaces
        </li>
        <li>
          &#8226; Unbiased expert advice
        </li>
        <li>
          &#8226; Give back to society and the environment. Learn more about Flexo’s <a class="static-page-links"
            routerLink="/joy-of-giving">Joy
            Of
            Giving initiative here</a>.
        </li>
      </ul>
    </div>
    <div class="flex-images">
      <img src="assets/images/why_go_with_flexo.jpg" alt="">
    </div>

  </div><br>
  <div class="flex-container">
    <div class="flex-images">
      <img src="assets/images/benifits_of_ distributed_offices.jpg" alt="">
    </div>
    <div>
      <p class="sub-heading">Benefits of Distributed Workspaces</p><br>
      <ul>
        <li>
          &#8226; Lower capex
        </li>
        <li>
          &#8226; Flexible terms
        </li>
        <li>
          &#8226; Reduced commute for employees
        </li>
        <li>
          &#8226; Greater employee morale and retention
        </li>
        <li>
          &#8226; De-risk corporate real estate strategy
        </li>
        <li>
          &#8226; Easy-to-scale setups
        </li>
        <li>
          &#8226; Business continuity planning
        </li>
      </ul>
    </div>
  </div>
</div>